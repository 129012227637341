module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aquele Hugo","short_name":"Aquele Hugo","start_url":"/","background_color":"#FFFFEF","theme_color":"#FFCC00","display":"standalone","icon":"src/assets/icons/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b33858a57bf9b61a58b6f3e3059c0a97"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./src/gatsby"},
    }]
